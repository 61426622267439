import React from 'react'
import { graphql, Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { Flex, Box } from '@chakra-ui/react'

import Layout from '../components/layout/Layout'
import Container from '../components/layout/Container'
import Section from '../components/layout/Section'
import SEO from '../components/elements/SEO'
import Hero from '../components/elements/Hero'
import AirportPagesNav from '../components/elements/AirportPagesNav'
import Book from '../components/elements/Book'

function Airport({ data }) {
  const page = data.prismicAirportPages.data

  return (
    <Layout>
      <SEO meta_title={page.title.text} meta_description={page.title.text} />

      <Hero>
        <BackgroundImage Tag="div" fluid={page.hero_image.fluid}>
          <Container
            py={['12em', '12em', '16em']}
            css={`
              z-index: 20;
              display: block;
              position: relative;
            `}
          />
        </BackgroundImage>
      </Hero>

      <Section>
        <Container>
          <Flex flexWrap="wrap" mx={-8}>
            <Box width={['full', 'full', 1 / 3]} px={8}>
              <AirportPagesNav />
            </Box>
            <Box width={['full', 'full', 2 / 3]} px={8} mt={[6, 6, 0]}>
              <h1>{page.title.text}</h1>
              <div
                dangerouslySetInnerHTML={{
                  __html: page.content.html,
                }}
              />
            </Box>
          </Flex>
        </Container>
      </Section>

      <Book />
    </Layout>
  )
}

export default Airport

export const pageQuery = graphql`
  query AirportPageSlug($uid: String!) {
    prismicAirportPages(uid: { eq: $uid }) {
      uid
      data {
        content {
          html
        }
        title {
          text
        }
        hero_image {
          url
          fluid(maxWidth: 1500) {
            ...GatsbyPrismicImageFluid
          }
        }
      }
    }
  }
`
